const emily = `
<p>
I am Emily. I am thirty-two. And I live in Bristol with my three-year old son and my long term partner. I have a full-time job. Trying to remember all the things&hellip; What makes me me. I don&rsquo;t know, I&rsquo;m just a normal person. I am currently furloughed but I do have a full-time job in administration for the transport industry which is&hellip; Or the motor trade, should I say sorry. It&rsquo;s one of those trades that grabs you and you try and get away from and then you never can. Although I love it. </p>
<p>
I was diagnosed with HIV in 2016. And my diagnosis was a massive shock, in the beginning. A very very big shock. I was not expecting that at all. And about... I&rsquo;d say I went on a kind of six months&rsquo; worth of just pretending it didn&rsquo;t happen and it wasn&rsquo;t there and telling myself I was coping fine and then I realised I really wasn&rsquo;t coping fine and then got in touch with our local HIV charity, Brigstowe, and, well, haven&rsquo;t really looked back since. They&rsquo;ve been amazing. </p>
<p>
I would say home to me is where I feel safe. It&rsquo;s a space that I&rsquo;ve kind of put my own touch on and it&rsquo;s also somewhere where my family is. Home is family and happiness and definitely a bit of a comfort blanket. </p>
<p>
My home is full of stuff. My home is often a little bit of chaos mixed with love. Chaos usually, definitely toys everywhere most of the time. I have managed to put them away today, kind of, they&rsquo;re still running around though. Hello Pooh Bear. And, yeah, we&rsquo;re definitely not, definitely don&rsquo;t have a minimalist house, I&rsquo;d say. I once - our last house that we lived - which was for just under a year in Devon, was a beautiful little village in the middle of nowhere, countryside. You looked out of the windows and it was just rolling hills and sheep and silence. But we couldn&rsquo;t... we weren&rsquo;t allowed to put anything on the walls so that to me was the saddest house to live in. There was no pictures of family and crazy horror movie posters. I like having posters or at least something that makes me laugh every now and again. Corey Feldman. I was lucky enough to meet the two Coreys while they were still alive. Well, one of them was still alive. Miss you, Corey Haim. It was at a, it was at comic like book convention many years ago. Yeah. It was a Lost Boys reunion. Although Kiefer wasn&rsquo;t there but the rest of the cast were there. It was cool. </p>
<p>
So, when I was diagnosed I was in a house-share&hellip; I love, I&rsquo;ve always loved living in house-shares even though it&rsquo;s often, you know, it gets too much and you think: &lsquo;I can&rsquo;t deal with all this mess like all the time.&rsquo; But, I loved, I love living with other people and being social and always having other people there to hang out with. But I think after I was diagnosed because I was in a very busy part of Bristol I felt like I couldn&rsquo;t get away and there was no peace and I really really craved just a quiet space. So in that sense I guess it did change it a little, but home now is a lot different to what home was then. </p>
<p>
I was quite closed about my status for quite a while. It wasn&rsquo;t until after I&rsquo;d met people at Brigstowe that I started opening up about it and then&hellip; And now I basically just tell everyone. But&hellip; So, my closest relationships&hellip; I don&rsquo;t think they were massively affected to begin with, purely because I didn&rsquo;t talk to anyone about it. And then after I decided to tell close family and friends it almost made me realise that, like, how lucky I was to have them there and have these friends and have family that were so supportive and accepting. </p>
<p>
Once I started slipping into a depression post-diagnosis, like, the depression was obviously a reaction to the diagnosis but it also was, you know, depression as well, it wasn&rsquo;t purely because of the diagnosis. Like the diagnosis probably sparked it off and I slowly declined downwards, but&hellip; So, when I started feeling really down I&rsquo;d find myself in some situations where I&rsquo;d usually be really comfortable, so&hellip; I was, I&rsquo;m a part of a few different groups, drumming groups and also used to go to some gym classes and&hellip; So just after diagnosis it almost made me react in the opposite way, so in the beginning I was like: &ldquo;Yes! I&rsquo;m still strong, I can still do this and take this on.&rdquo; And then towards the end of the downward spiral I&rsquo;d go these places where I&rsquo;d usually be surrounded by, you know, my peers, my friends, I&rsquo;d feel so comfortable&hellip; I suddenly started feeling like an outsider and&hellip; yeah. So I had a bit of a break from my usual groups and things. Which was, which now I realise it was just because I wasn&rsquo;t talking about it. And after I did talk about and everyone in the groups now knows why I suddenly had to leave and just have a bit of breakaway, they&rsquo;re&hellip; Yeah. they&rsquo;ve become really supportive too. </p>
<p>
I do samba drumming so it was a big group of drums. A big family. But yeah, but things like that were always, that&rsquo;s&hellip; Is something that&rsquo;s really important to me. It gives life that extra spark. I try to, try to do what I can. We&rsquo;ve got a little local community here. I mean, it&rsquo;s not been&hellip; I&rsquo;m surrounded by really lovely neighbours so I&rsquo;m very lucky. We haven&rsquo;t had much going on this year, obviously, through a lockdown. Yeah. And it&rsquo;s something you kind of&hellip; You&rsquo;re not forced to but I think you just naturally fall into that once you become a mum. You just have to start meeting everyone&hellip; Meeting local mums, and hang out with mums. </p>
<p>
Luckily I haven&rsquo;t had many negative reactions. I think if I have I&rsquo;ve just forgotten about it. And I think if I have had any negative it would have been from people I might, I don&rsquo;t really know so their opinion doesn&rsquo;t really matter to me anyway. But I&rsquo;ve definitely got a few positive ones. I say the, the ones that I always feel proudest about is when I&rsquo;ve told someone about my status and it&rsquo;s made them think twice and they&rsquo;ve said: &ldquo;I didn&rsquo;t think this could even affect someone like me, I&rsquo;m going to go and have a test.&rdquo; And I&rsquo;ve been like: &ldquo;Yes!&rdquo; That&rsquo;s what I want, that&rsquo;s, my work is done. </p>
<p>
So, those are my favourites. I&rsquo;d say one of the very first people I told was my, now long-term, partner and he, when he first heard, said that: &ldquo;It doesn&rsquo;t, it doesn&rsquo;t change who you are.&rdquo; Which I thought: &lsquo;Oh thanks, that&rsquo;s nice.&rsquo; And then he went a little bit quiet which I totally expected and at this point I&rsquo;d prepared myself to just accept that I can&rsquo;t control what he then does. So while he was being quiet he had actually gone to the, I think it was a sexual health clinic or his GP possibly and asked them about HIV. And that was just, you know, above and beyond what I&rsquo;d expect anyone to ever do, so&hellip; Yeah. Well done, sir. He&rsquo;s a keeper. </p>
<p>
I&rsquo;ve had plenty of other, plenty of others but&hellip; Trying to think, trying to think. I&rsquo;ve told hundreds of people, so I can&rsquo;t remember... It all becomes a blur in the end. I&rsquo;ve heard some terrible stories and I can&rsquo;t believe people are like this. But I&rsquo;m very very very lucky. </p>
<p>
I think safe space isn&rsquo;t just a physical space. It&rsquo;s definitely like a mindset, &lsquo;cause sometimes, sometimes, like for instance, I love being at home but I also get really bad cabin fever. I have to, I have to get out. I&rsquo;m quite lucky because there&rsquo;s quite a few nice&hellip; I guess Bristol in itself has got quite a lot of nice green spaces. So yeah, I think safe space starts from your own head. I guess safe space follows you around wherever you go, you just gotta, you just gotta remember that. </p>
<p>
I think most people would know what it feels like to think: &lsquo;Oh I&rsquo;m fed up and I just want to get away from here.&rsquo; But at the end of the day, you&rsquo;re still you&hellip; </p>
<p>
I&rsquo;m a strange individual. I&rsquo;m definitely very smiley. I&rsquo;m just, I think I&rsquo;m just smiley and happy and weird. Mainly. Smiley, happy, weird&hellip; I definitely, I think I&rsquo;ve become stranger since having a child because I&rsquo;m more outwardly strange now, whereas before I&rsquo;d tend to&hellip; I mean, I thought I kept a lot of my like strange noises and thoughts to myself but actually now I think about it I don&rsquo;t think I did because everywhere that I&rsquo;ve ever worked people always either called me a clanger or &lsquo;there&rsquo;s the crazy cat-lady that&rsquo;s always miaowing&rsquo;. &lsquo;Cause I&rsquo;m often making different noises. But I think I do it without realising. Sometimes. </p>
<p>
I like to think of me being a little bit wonky. But then I like people&rsquo;s wonky. People are too obsessed with being, like, perfect, and you know, just, dunno, my boring&hellip; And everything people think is a flaw to them I always appreciate. I like people&rsquo;s strange quirks. </p>
<p>
I guess the only other thing I&rsquo;d add would be about people&rsquo;s reaction in general&hellip; So, usually when I&rsquo;ve told like close friends and family and my peers about my status it&rsquo;s generally a positive reaction but I think the general public, or general people, would, would often&hellip; What am I trying to get at? They often don&rsquo;t really have a good thing to say. And I think general people can be quite judgemental. And myself, for myself, I don&rsquo;t really often, you know, what other people say, you know, to me if people have to say something or be negative about something then that&rsquo;s what they have to do, you know. Thanks for your help but carry on, it&rsquo;s not going to affect my day. And I think a lot of people these days just, I dunno&hellip; I almost feel like they&rsquo;re obsessed with&hellip; I feel like I&rsquo;m part of a blame culture and they&rsquo;re obsessed with just letting everyone else know that they&rsquo;re absolute saints and we live in a nation of virgins who all wear condoms every time they have sex, so&hellip; I think to, to, to people that aren&rsquo;t in the HIV-community, to&hellip; No, I won&rsquo;t say that. Just shut up! No. Just, I dunno, maybe, if you&rsquo;re not going to be helpful and you don&rsquo;t want to help our future generations of people then, then just go and find something else to whinge about, you know. </p>
<p>
We&rsquo;re not all perfect. And flipping no-one&rsquo;s perfect. But for some reason everyone thinks they&rsquo;re better. </p>
<p>
This is the thing with the general public&hellip; I think most of the people who go out of their way to try and set other people straight and say: &ldquo;Well, y&rsquo;know, you know there&rsquo;s risks, you should have thought about it...&rdquo; You know, are the type of people who I don&rsquo;t think we&rsquo;re ever going to change the opinions of. They&rsquo;ve got their own opinions for everything so we&rsquo;re just going to have to accept that and try and get to the people who, you know, we might change the minds of or we might educate them about&hellip; </p>
<p>
So, I&rsquo;ve seen quite a few different, like, AIDS-related documentaries and films and most of the time I&rsquo;m just like: &ldquo;Ugh, let&rsquo;s turn it off.&rdquo; It&rsquo;s too much all this, trying to spread the flipping wrong message. I&rsquo;m getting the wrong idea with these things, you know. But, I don&rsquo;t know, I can imagine reviews for &lsquo;It&rsquo;s A Sin&rsquo; being kind of 50/50 of, you know, look how far we&rsquo;ve come that AIDS doesn&rsquo;t really exist in the UK anymore and, obviously, there&rsquo;s going to be the flip-side, there&rsquo;s going to be homophobic reactions, you know&hellip; </p>
`;

module.exports = emily;
