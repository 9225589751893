const anita = `
<p>
Hello, I&rsquo;m Anita and I am a playwright. I&rsquo;m an instructional designer and also a garden designer. And I&rsquo;m nearly fifty. I will be in three weeks. I never thought I&rsquo;d get there. And proud to be here. </p>
<p>
Sometimes I think home is somewhere I&rsquo;ve never been. I&rsquo;ve never been attached to places, even though I&rsquo;ve lived in some places for quite a long time. I think home is a person, home is a state of mind, of a place of comfort that is with someone and the place doesn&rsquo;t matter. And that can be a group of people, too. I&rsquo;ve lived in flat-shares that have been a great little family and a community and that&rsquo;s felt like home even though it was actually a shithole. And I&rsquo;ve lived in a nice house with a &hellip; that felt sterile and cold and I didn&rsquo;t want to be there. So home is not a place. </p>
<p>
When I&rsquo;ve tried to make a home, I&rsquo;ve always tried to make a den. I like a small space. When I lived in a big house, I had a little den in the cupboard under the stairs. In this house, which is the closest I have to a home, my office is the smallest room. And when I was a child I used to like to make dens out of chairs and blankets and I like to retreat. </p>
<p>
At the moment my office is a very accurate representation of my life in that there&rsquo;s loads of different project books. There&rsquo;s garden design plans, there are books that are half-read. There&rsquo;s a piles of paperwork and post-it notes and... It&rsquo;s not usually that chaotic but life seems to be just endless, small multitasking projects at the moment. Normally I like things to be quite neat. There&rsquo;s a wall of books of one side. Behind the desk there is another workspace which is a completely blank, white worktop which I keep clear apart from large plants at either end. And there&rsquo;s nothing on the walls apart from a Scrabble painting. The Scrabble painting is a, a 70cm by 70cm square made of Scrabble letters that were supposed to be arranged entirely randomly so they didn&rsquo;t make any actual words but accidentally words were made. I like its randomness. </p>
<p>
I don&rsquo;t think my concept of home changed. I think I just realised that I was carrying it with me rather than it being attached to a physical building. And because the changes that were happening to me were in my body, it made sense that I had to move. So, it&rsquo;s the opposite way around for me than for many people who were diagnosed because I was diagnosed when I was very seriously ill and I was in hospital on a ventilator. And I had been ill for a long time before that without really realising, so my&hellip; My self was depleted, and when I was diagnosed and I was put on medication, my self started to come back to full strength and that destroyed my home. I no longer fitted in the space which I had created for myself. So, I left a relationship and a place and carried something new away. </p>
<p>
I never felt excluded from anywhere because I was positive. Being diagnosed suddenly and, in my circumstances, kind of publicly because other people knew about my diagnosis before I did, because I was so ill they were coming to say goodbye, so&hellip; So, I had&hellip; People around me had to deal with the fact that I was positive before I knew it myself and then as soon as I was able to I had to kind of undo the panic and re-explain to people what this actually meant and this was the worse, the worse place it was going to be and I was going to be able to live and live well. So, I was fortunate in that I lived in a reasonably tolerant community. I was involved in theatre and I think theatre communities have always been quite welcoming of difference, so I didn&rsquo;t feel excluded there. I didn&rsquo;t consider that there was anything I shouldn&rsquo;t be doing or anywhere I shouldn&rsquo;t be going. </p>
<p>
The doctors explained to my parents and my husband that I was HIV-positive and what that meant, but the ripple-out effect through my friends and family was kind of Chinese whispers, so&hellip; There&rsquo;s no non-sensational way to say Anita&rsquo;s dying of AIDS. It did cause a panic, it&rsquo;s a big word. Because the image is that that is not something you could ever recover from, when actually my body recovered extremely well and my viral load became undetectable very fast. My CD4 count rose and has remained stable to almost, almost normal-person level and that means that I&rsquo;m no longer in danger. So these were things that I was only just understanding myself and I was having to sort of convey that to other people to undo the panic, because there&rsquo;s been very little education about HIV in the UK in between the tombstone/iceberg adverts of the &lsquo;80s with John Hurt intoning &ldquo;don&rsquo;t die of ignorance&rdquo;, through to the present, unless you&rsquo;ve been going through school years. And actually, through a block of the &lsquo;80s thanks to Clause 28 there wasn&rsquo;t any education about gay sex. You&rsquo;d have missed the messages, you&rsquo;d have missed the messages of how to protect each other successfully, how to stop transmission and how to live with HIV healthily if you&rsquo;ve got it. Those messages are still not clear. </p>
<p>
Two positive experiences from living with HIV. And both of them have come through advocacy, actually, of, erm, being open about my status and speaking about it and trying to normalise it. One was when I spoke at the British HIV Association conference and I told my story and afterwards I got a standing ovation, and I didn&rsquo;t expect this to be an emotional moment for me. I&rsquo;ve told my story before, but just to be inside the HIV-community and telling it and feeling an upwell of support around me, I was in tears. Yeah. I felt, I felt like I was literally supported in this bubble of hope and care. And another was just I&rsquo;ve been mentoring people with HIV and I was with one person who had a journey that involved recent diagnosis and managing alcohol addiction at the same time and we went on a four-or-five month journey and that person came out the other side. That person came out of the mentoring experience having successfully completed rehab, having seen a way forward with HIV and feeling able to have a relationship in the future to being able to see grandchildren, to starting to take tentative steps towards retraining and getting work, and I&rsquo;ve never been more proud of a person than I was when they came out of the other side of that. </p>
<p>
Generally speaking, I&rsquo;ve been quite open about my status from the beginning and&hellip; but I have occasionally encountered stigma. One was a friend of my husband&rsquo;s. His wife wouldn&rsquo;t let me stay overnight in their house in case I gave HIV to the children, or was in some way a bad influence. I don&rsquo;t understand where her fear came from, but I didn&rsquo;t feel welcome and nor did I wish to be around her, actually. </p>
<p>
I&rsquo;ve received stigma in healthcare services. I joined a new dental practice and told them I was HIV-positive and when I went to meet the dentist he was covered from head-to-toe in plastic, I could barely see his eyes. He refused to shake my hand and he looked scared. He basically said: &ldquo;I can look at your teeth but I can&rsquo;t do any procedures on you, we don&rsquo;t have the decontamination facilities here.&rdquo; At which point I said to him: &ldquo;Well, I&rsquo;m going to report you to your governing body because if you cannot protect your other patients from my HIV, you certainly cannot protect anybody here from hepatitis, flu, even the common cold and I wrote the right letters to the right places and he got retrained. </p>
<p>
Oh and I had to have that whole conversation with the door open with me standing on the threshold of the waiting room, discussing my HIV-status in front of receptionists and everyone else who is wanting to listen. I wonder if any of them had the blood-borne viruses conversation and thought twice about being treated. I hope so. I self-stigmatise myself, as well. In a rash moment, I decided to join a karate class just because it was around the corner and it was kind of interesting and even though we weren&rsquo;t sparring with each other, just because I was just a complete beginner, I looked at this space and thought: &lsquo;I know that my viral load is undetectable and I am untransmittable but I actually don&rsquo;t want to have to explain that to people here. And I don&rsquo;t want to alarm anyone.&rsquo; So I walked out. I did self-stigmatise in that moment. I would have been useless at karate anyway, I&rsquo;m a complete wimp. </p>
<p>
My diagnosis was immensely traumatic for everyone who was around me, because I was so so ill. It was horrific and I wish I could have spared the people around me that experience. It was&hellip; It was not only difficult news, the whole thing was visceral and&hellip; It was a, it was a horror movie. So erm&hellip; There&rsquo;s so many stops along the way where that could have been prevented, including&hellip; Is that a dog? I might just bring him in. I&rsquo;ve no idea what you&rsquo;re going to get here. Ratty, let&rsquo;s have you over here. Tuck in. </p>
<p>
I think because I was so ill and it was&hellip; and my diagnosis was late, and it was traumatic and I was in hospital and I was dying, it was a nightmare. It was a nightmare for everyone around me. It was&hellip; It was really traumatic and I would have dearly loved to have spared everybody that experience. Everyone was incredibly supportive at the time and they were on my side and willing me through it, praying, singing folk songs, reading me, to me from Hello! magazine, whatever it was that they felt they could do to get me through. So I haven&rsquo;t felt judged but people see me as fragile in a way that they perhaps didn&rsquo;t before, and it&rsquo;s taken me quite a long time to reassure people that that&rsquo;s not going to happen again, I&rsquo;m okay. Really, I am okay. </p>
<p>
I think a safe space is always to do with the close friendships I have around me. My husband but also my female friends and old friends. It&rsquo;s also a mental space, which is&hellip; It&rsquo;s the same place that I retreat to when I&rsquo;m creating. Which is part-meditative&hellip; Which is partly meditative but it&rsquo;s also moving elements around at very high speed. Kind of trying different arrangements of story, thought, character, plants. Whatever it is I happen to be working on. And I can move myself into that space from actually quite emotional spaces or quite stressful spaces. I can even drop into it for a fragment of time. Thank you Ratty. As a writer I&rsquo;ve kind of trained myself to drop into that space and use it quite effectively even if it&rsquo;s for a short duration. </p>
<p>
I think my first community is the household community, which is partly being part of a dog-pack actually. That community is quite vocal at the moment, thank you Ratty. That community has brought me into the community into which I live, because I&rsquo;m constantly, sort of, trying to find new walks and new footpaths and we&rsquo;re bumping into people and we get to know the landscape and the seasons. The dogs have really placed me in this place. So in some ways I think I have a better connection to where I am now than anywhere I&rsquo;ve been, &lsquo;cause of these critters. My other communities are kind of more virtual. I have the, I have my community of people I make radio with. I have my community of people&hellip; I have my community of people I make learning products with. The other community that I am connected with is the HIV-community and that&rsquo;s something that&rsquo;s only happened in the last two or three years, where I&rsquo;ve been, become a mentor with the Sussex Beacon, I&rsquo;ve become a speaker with Terrence Higgins&rsquo; Positive Voices because I want to tell this story about &lsquo;can&rsquo;t pass it on&rsquo;. I want to tell this story about &lsquo;we live well&rsquo;. I also want to tell this story about &lsquo;we can end transmission&rsquo;, the 2030 target the Government has said and that people don&rsquo;t need to live with stigma and trauma. They don&rsquo;t need to live with ill health. But when people with HIV encounter these problems along the way, there&rsquo;s a community out there that can help you through it. </p>
<p>
Describing myself? My favourite animal is the armadillo, which if you&rsquo;ve never met an armadillo, they&rsquo;re actually quite bold. They wander around poking their nose into things. They can be quite loud, a little bit clumsy. They&rsquo;re a little bit secretive because they&rsquo;re nocturnal, but the important thing is they kind of carry their own shell. They carry their own home. So in a minute&rsquo;s notice they can curl up into a ball and they&rsquo;re completely self-protected. So I think of myself as a bit of an armadillo. </p>
<p>
I also, I&rsquo;m also going to throw this one in, it&rsquo;s not a question, but I also see myself as a home. I am a habitat, I am a biosphere and I carry, as everyone does, a raft of little microbes, viruses, bacteria, fungi, other things that make eggs, things I don&rsquo;t have names for. And among them is the HIV virus. So, like it or not, I am a home. </p>
<p>
I&rsquo;m planning my 50th birthday party at the moment and I&rsquo;ve probably been living with the virus for 25 years, so I think that kind of makes it a sort of silver wedding. It&rsquo;s the longest relationship I&rsquo;ve had with any organic being and &ndash; please try not to be so bored, Ratty &ndash; and&hellip; I&rsquo;ll start that again. It was a bit shit, anyway. I&rsquo;m going to be 50 this year and I&rsquo;ve been living with the virus for 25 years, which is a kind of silver anniversary. It&rsquo;s a very long-term relationship and we&rsquo;re both kind of dependent on each other. One could say we&rsquo;re toxically co-dependent but &ndash; like it or not &ndash; here we are; we&rsquo;re bonded. And it&rsquo;s the end of the line for the virus because I&rsquo;m not going to pass it on. Also weirdly it&rsquo;s the end of the line for me because in my 30s I decided I was not going to have children, because I was HIV-positive. With different information a different person might have made a different decision, but anyway&hellip; So. I am planning my birthday party and we&rsquo;re both going to have a cake. I&rsquo;m going to have a cake and so is the virus and it will be very interesting to see if any of my friends eat the virus cake. </p>
<p>
My cake is going to be vanilla sponge. I might layer it up to look a bit like an armadillo and the virus cake is going to be the chocolate cake which is the one that everyone&rsquo;s going to want to eat. It&rsquo;ll be covered in green icing and spiky bits. I don&rsquo;t know if it&rsquo;s even possible, I can&rsquo;t bake a cake. I can&rsquo;t even bake a potato. </p>
`;

module.exports = anita;
