const ashley = `
<p>
Okay. So, I&rsquo;m Ashley Hawken, I&rsquo;m nearly 61 years old. I was a coach builder but now happily retired and yeah, so&hellip; So I got positive in nineteen&hellip; When I was 50, the&hellip; nearly the same week as my birthday. Or maybe the week before. My seroconversion was directly after my birthday, so it sort of ties up. Timing. Perfect, yes. I looked terrible at my birthday. I did look terrible as well. And, yeah, so seroconversion was just after my birthday, and I was undiagnosed for five years. In that meantime, I&rsquo;d had shingles, everything. In the end I ended up with no platelets, CD4 of 15 and a viral load of 32 million. Yeah, 15 it was. Yeah, and I had thrush, genital warts and bad skin. So you name it&hellip; Oh, and I had appendicitis that was undiagnosed. And that week before I went in to hospital, the, the doctor gave me painkillers to get rid of the appendicitis. The appendix had burst twice. So my body was full of poison. So, yeah.
</p>
<p>
That was six years. So that was 2015, I was&hellip; I was diagnosed nearly to the day of my seroconversion. June the 3rd was my seroconversion and then, June the 3rd I was told I was HIV-positive. So it&rsquo;s fine. So I went on for five years. So I survived five years and, anyway&hellip; So, I was in hospital for two nights and come home and then they called me. Kieran phoned me up, said: &ldquo;I&rsquo;m a specialist nurse.&rdquo; He didn&rsquo;t say what about, he just said: &ldquo;I&rsquo;m a specialist nurse.&rdquo; So my Dad took me in and, er, so I sat down, he said: &ldquo;You&rsquo;re HIV-positive but fortunately you haven&rsquo;t got the rest.&rdquo; And I nearly said: &ldquo;Well, I&rsquo;ll go and get them then.&rdquo; Just to make the set. But, anyway, he gave me antibiotics immediately and&hellip; so I wondered what that was all about. I had an appointment the following Tuesday. I went in and found, found the viral load and on top of that I had no platelets as well, so&hellip; And they, and it turned out the my appendix was a fifty-mil mass of pus. So, yeah. So he put me on, straight on&hellip; I can&rsquo;t remember what I was on, might have been tri-med, and then they got the viral load down quite quickly actually, three, about three or four months, maybe six months I was down to 15 million and then by that time, no: it was quicker than that &lsquo;cause I had an operation, &lsquo;cause I went back to work in December. So I had my operation and they wouldn&rsquo;t do it &lsquo;cause I didn&rsquo;t have any platelets. So they said, I said well: &ldquo;What can I do?&rdquo; They said: &ldquo;Well, we can give you a platelet transplant.&rdquo; I said:&rdquo;Well, just do that then.&rdquo;
</p>
<p>
Yeah. Yes, it&rsquo;s quite hard. But I didn&rsquo;t realise, my hospital, my operation was due to be 1 o&rsquo;clock in the afternoon. But I didn&rsquo;t realise that at that time they were moving HIV patients to the last one of the day, so I got moved, from 1 &lsquo;til 3, so I become the last one of the day. I didn&rsquo;t realise that at the time. But, I didn&rsquo;t mind, I wouldn&rsquo;t have cared anyway. No point getting worried up about it. So, they, so they took my appendix out and six inches of stomach as well. Erm, yeah. And they done a keyhole surgery through my bellybutton. But they cut straight through my bellybutton. To get it out.
</p>
<p>
So, yeah, so it took another two months and anyway I went back to work. And I went part-time then, to four-day-a-week. I was a service engineer and used to be on the road, but they took me off the road. And I used to like getting out with the group, just getting away. But they took me off the road, so I just sort of played along with it. And I did enjoy work, and I, and you know, if it was right I&rsquo;d go back now, but, so. So in the end I was down to four-day-a-week but then I thought: &lsquo;I&rsquo;ll just retire.&rsquo;
</p>
<p>
Well, I didn&rsquo;t tell everybody. I told the service engineers who I was working with. I was the coach builder. I used to build posh medical units, stuff like that. Hospitality, you know, things like that. But, anyway. I told the engineers I was with but I didn&rsquo;t tell the actual coach builders. And I used to walk in the factory and everybody used to, like, look over and start, you know, but I just played along with it, but I thought just&hellip; Just encourages this little game you got. And then one day I thought: &lsquo;No, I&rsquo;ll just tell the gobbiest person over there.&rsquo; So that&rsquo;s what I done. And within five minutes everybody knew.
</p>
<p>
Because being non-binary trans it&rsquo;s quite difficult to fit in. &lsquo;Cause there&rsquo;s no&hellip; It&rsquo;s just becoming acceptable now, but there is no box. What box do you put me in, you know? There&rsquo;s no&hellip; I think all non-binary trans people we all suffer with the same thing, there&rsquo;s no, there&rsquo;s no actual box. Especially for non-binary people because, like a trans-man or a trans-woman, that&rsquo;s, that&rsquo;s a box to go to, but non-binary you&rsquo;re just floating in aether.
</p>
<p>
Yeah, where do I fit? You know. Where do I fit amongst this? There is no, erm&hellip; Some people don&rsquo;t understand, a lot of people don&rsquo;t understand it. One day you wake up feeling male, another day you&rsquo;re like really female, or, or just somewhere in between. There&hellip; And what is this in-between space? It&rsquo;s like going in a gay bar. My mentee has said it. He said, they said they went in a gay bar but they don&rsquo;t really fit there. &lsquo;Cause as a trans, non-binary person you don&rsquo;t really belong.
</p>
<p>
Yeah. That furthered me, it furthered me to drive me on to the person I am, now. I am... I don&rsquo;t really care what anybody thinks of me now. That&rsquo;s why there&rsquo;s photographs on Brigstowe in make-up and a skirt on and I don&rsquo;t really care, I just be me. Because it&rsquo;s hard to live a lie. It&rsquo;s too hard to live a lie. And it&rsquo;s, it&rsquo;s depressing, as well. Exhausting. Yeah, it is. Completely. You know, it&rsquo;s like&hellip; My Mum&rsquo;s 85, I don&rsquo;t tell her anything because it&rsquo;s easier she don&rsquo;t know about it, she haven&rsquo;t got to know. But, my sisters know that come from the article and the rest of the world knows but she&hellip; No. But, you know, when I&rsquo;m away from them I just live my life as a trans, non-binary person and I, and I don&rsquo;t really care what the world thinks.
</p>
<p>
Yeah, yeah. I think the HIV&rsquo;s, has driven me on to be the person I am, &lsquo;cause it&rsquo;s a driving force as well. It makes you&hellip; It just strengthens you. And I don&rsquo;t know about anybody else with it but I found inner strength from getting over it. From being where I was. From a near-death situation you&rsquo;ve got to&hellip; You know. You&rsquo;ve got to take the, I take the&hellip; I take strength from the fact that I&rsquo;ve survived it. I was strong enough to survive it. It might have killed some people earlier. So there&rsquo;s something&hellip; There&rsquo;s something in me that makes me strong.
</p>
<p>
And&hellip; To beat, yeah. But, but when I talk to my&hellip; &lsquo;Cause my mentee&rsquo;s been newly-diagnosed and now and I say why you&rsquo;re really healthy. &lsquo;Cause they&rsquo;ve got CD4 counts of 800, you know, they&rsquo;re&hellip; it&rsquo;s done no damage to them. But people don&rsquo;t realise that even though I&rsquo;m undetectable, there is, it&rsquo;s done damage to me. I&rsquo;m tired, it&rsquo;s damaged my voice. I got, I got scars all over my stomach because of it. And it is still there. It&rsquo;s still&hellip; and I call it the dark place. Everybody, I think&hellip; Everybody with HIV they go back there occasionally, you know? There&rsquo;s not many people that don&rsquo;t go back there. But it is, it&rsquo;s not a&hellip; It don&rsquo;t take long to click out of it, now. It&rsquo;s just&hellip; And there&rsquo;s no point in looking back and there&rsquo;s no point in getting angry about it. &lsquo;Cause I had great fun getting it.
</p>
<p>
I used to go to Brighton. I used to go out at 6 o&rsquo;clock at night, I&rsquo;d come back, come back 6 o&rsquo;clock in the morning, after going to the sauna all night, and the bar and then... I had a wonderful time, I had&hellip; All the dark rooms and the dodgy places, it was&hellip; Well, why not? Yeah. And I always got on better with casual sex, anyway. I never, never tied down. I be honest, you can say I should&rsquo;ve used a condom, but, but how often does that apply?
</p>
<p>
And I wouldn&rsquo;t change anything for the world, to be honest. Because of, because the HIV-community is a tremendous community, really, I think is&hellip; You know the ones that are especially around Brigstowe, it&rsquo;s tremendous, you know? You still get&hellip; I think the rest of the world is - outside the community, even the gay society - they&rsquo;re worried because they don&rsquo;t know anything about it and they don&rsquo;t want to know anything about it. So it&rsquo;s all about education, because it&rsquo;s gone under the covers a bit, hasn&rsquo;t it? It&rsquo;s sort of gone away, so, it&rsquo;s not with us anymore.
</p>
<p>
I do clinic mentoring as well, so. I don&rsquo;t know how much more I can be involved really, but&hellip; I&rsquo;ve only done it to give back really, because&hellip; Going back to when I was diagnosed, so, anyway, eighteen months in and I took my car in for a service and I was in town and I went and see Bonnie at THT on West Street, it was there then. And she said: &ldquo;I&rsquo;ll put you onto Lauren.&rdquo; And I didn&rsquo;t... I didn&rsquo;t realise &lsquo;til after, the low place I was in. &lsquo;Cause I, and I come home from that after picking the car up, and I come home and had a cup of coffee and I thought: &lsquo;Do you know what? I hadn&rsquo;t been out for six months.&rsquo; I hadn&rsquo;t been in a pub. Hadn&rsquo;t seen my friends. I just went to work, come home, had some tea, went to bed. And that&rsquo;s the same, and that&rsquo;s all I done. Day in, day out. And I realised&hellip; All the cars, the garden was in a state, the house was&hellip; I&rsquo;d just given up on life basically. And then Lauren phoned me and I, so I done the newly-diagnosed group at Brigstowe and then I had a mentor. And, so&hellip; I really come out of that within six months. I was turning like &lsquo;round the circle, you know, I was coming out of that dark place. And then, then I volunteered to be training for a mentor, senior mentor. So, I passed that and I got 100% on the NVQ. I don&rsquo;t know how I managed it but I done it. And then, yes, so&hellip; At that same time I joined Campaigns, which was Polly, before Aled. Aled joined as well, that same time. So, so Aled joined then&hellip; I become a mentor, I joined Campaigns and then I just went full more into HIV, you know. You know, let&rsquo;s get this message out there.
</p>
<p>
And that newly-diagnosed group, when you meet ten people and there&rsquo;s straight, gay, males, females, they&rsquo;re all, and they&rsquo;re all in the same room and you realise you know, you ain&rsquo;t the only one with it, and there&rsquo;s one, well, Emily. Well, I met Emily there and we were all thinking: &lsquo;how did she get it?&rsquo; but you get it by sex, don&rsquo;t you? It&rsquo;s just&hellip; I know the haemophiliacs used to get but I don&rsquo;t think they do now, because of the screening&hellip; Everybody had unprotected sex didn&rsquo;t they, that&rsquo;s how they got it. There&rsquo;s no need to ask a question.
</p>
<p>
I hadn&rsquo;t been out of the house for six months from&hellip; Well, I was in, within a year, the first year, I was alright. I was diagnosed, I was diagnosed and then&hellip; I was alright for a year and that I, that&rsquo;s, yeah, I then, I sort of really did give up. It was&hellip; And you don&rsquo;t realise how psychological it is. A lot of people don&rsquo;t realise it is psychologically damaging because you just shut the rest of the world out &lsquo;cause it&rsquo;s easier to shut them out &lsquo;cause why do you wanna answer questions? Why do you wanna, you know, why do you wanna relive that horror? Of that? It&rsquo;s not, you know&hellip; And especially back to seroconversion when it lasted a tremendous three months and I had&hellip; It was horrific. I had diarrhoea and sickness for six months, for three, for two months. I was like nine stone, nine-and-a-half stone or something, you know. It was the most horrendous thing that ever happened.
</p>
<p>
This is, this is my happy place. This is me. This surrounding&hellip; because I built it and it&rsquo;s built around me, this is, and all this stuff around me, all these Gromits and these pictures and these model cars: this is me. This is my happy place. Yeah&hellip; And this is like a sanctuary, as well.
</p>
<p>
Bed is a safe space. Yeah, bed&rsquo;s&hellip; Yeah, it&rsquo;s&hellip; Well the whole place is a safe space because I can shut the front door and&hellip; I used to have it that it&rsquo;s so safe that when I was working, I used to turn the mobile off and when I had a landline I used to pull the landline and I&rsquo;d turn the Wi-Fi off and that&rsquo;s what I used to do. That was the only way I could physically deal with, deal with it for the eighteen months to a year. And that was just after I was diagnosed, as well, because I couldn&rsquo;t see any way of me ever going out again in that point, so, so this was&hellip; This was it. This was my life. And, er, it still is a lot, well, it still is my life.
</p>
<p>
It is armour as well. It&rsquo;s armour from the rest of the world. &lsquo;Cause, I mean, you gotta have&hellip; Everybody gotta have somewhere to go. For me, it&rsquo;s not travelling, I don&rsquo;t like, I&rsquo;ve done enough travelling. When I was a service engineer. So maybe that, being a service engineer and me on the road, I&rsquo;ve always looked forward to coming home. So I used to drive two or three nights away, I used to hate it. &lsquo;Cause I was away from here. So I used to try and do it in a day, and get back. And then that&rsquo;s when I realised that this place was as important as it was. And when I thought about selling it, I realised I can&rsquo;t.
</p>
<p>
And then, and then I got my other sanctuary, which is the garage, the cars. If I wanna go and really lose myself I can. Forever. I used to spend hours out there. And the garden. You got a garden to play with, so&hellip;
</p>
<p>
I think, the way it looks from the outside, the fact it&rsquo;s stone-built. It&rsquo;s all stone-built and it&rsquo;s got an original Victorian sash windows, semi-Georgian, so they&rsquo;ve been there since 1844, so, especially on the front. And I built the extension, which is on the side. So it&rsquo;s stone-built, double Roman clay tiles on the roof. Built in 1844, original sash windows, two&hellip; Well, the walls are 21 inches thick, in places. It all leans. It&rsquo;s all subsiding. And when I bought it the doors wouldn&rsquo;t shut because they were 5/8s an inch out of square and it was all like that. Everything&rsquo;s, everything leans on&hellip; Even, you see&hellip; The house is out of square as well, sort of diagonally out of square. It&rsquo;s got original doors and it&rsquo;s quite brightly coloured and it&rsquo;s full of Gromits, model cars, photographs of my cars. And books. And loads of paintings. And a lot of nudes, as well.
</p>
<p>
Yeah. I realised you gotta have somewhere to, gotta have somewhere to be yourself. I&rsquo;m happy. And like I say, when I, when the diagnosis come along and what I realised how important the non-binary trans bit become, this was, this was the place I could first be me, as, as a person. My true self. So this was, this was important to me. The whole, the whole thing become important to me then, &lsquo;cause this was the first place I experimented in being me. As a true person, you know. But without this, I don&rsquo;t think I could&hellip; I don&rsquo;t think I could be me. I couldn&rsquo;t share it. I couldn&rsquo;t share this house with&hellip; &lsquo;Cause I don&rsquo;t think people und-... I still don&rsquo;t think people understand non-binary, so how can you share it with somebody who&rsquo;d never understand it?
</p>
<p>
It&rsquo;s an invasion of privacy and then when I go out as me then that&rsquo;s my choice to go out as me, but&hellip;
</p>
<p>
And the strongest thing I think come from... I think it come from&hellip; There&rsquo;s a couple, actually. I think it was Darren from Bristol Pride. Darren Carter. He&rsquo;s, he was the CEO of Bristol Pride. I went to, it was LGBT history month a couple of years ago and Darren was there. And I had a strong reaction from Darren, but also it was Henry, as well. Henry who runs Off The Record. [MB: Oh, I know.] And it was all, all-encompassing as well, you know, it was just&hellip; And for the first time ever I could be an HIV-positive person who was also trans non-binary and I went to Transcaf and, so it was Henry first and then it was the rest of Transcaf, so it was a lot of people who were trans or non-binary, but, I get more&hellip; I think it&rsquo;s, the reaction is more positive from the non-binary trans community than it is from the gay community. And of course, work as well. I think my workmates were brilliant, as well. There was no, there was no&hellip; Question that they were going to exclude me from anything. And my boss, as well. So, it generally has been really good.
</p>
<p>
Yeah. And my boss as well. Because I was being bullied at one point and he noticed. So he found out who it was and I said: &ldquo;What you gonna do then, James?&rdquo; He said: &ldquo;I&rsquo;m gonna go and talk to him.&rdquo; And, so it was somebody from the coach-works, so I went in and that person was&hellip; Just turned his back and walked away from me. And on my retirement card he did actually sign my retirement card. There was one case, yeah. Just one. Well, there was a little gang, you know? You get one ringleader and you get a little gang that&hellip; Because I was so forthright I think they, that person didn&rsquo;t have the guts to face me.
</p>
<p>
And a few occasional sexual partners, as well. Because, but I suppose we all suffer that one. But then you gotta say, then I say: &ldquo;Well, I know what I&rsquo;ve got, what have you got?&rdquo; Yeah, I get checked every four months, what do you get? You know, when did you last get checked? I know exactly what&rsquo;s wrong with me, so&hellip;
</p>
<p>
So, so I started volunteering at Brigstowe and she [Ashley&rsquo;s mother] then said: &ldquo;Why you doing that?&rdquo; And I said: &ldquo;Well, give, to give back what I&rsquo;ve taken.&rdquo; And then she sort of accepted that and then horror-of-horrors, I done a television programme on Inside Out West. So I done a television interview and it went out, my god when was that? Not this time last year, last February. I got it on the hard-drive, I can&rsquo;t watch it. I really can&rsquo;t watch it. I just can&rsquo;t. I&rsquo;ll watch it one day. I can&rsquo;t deal with it now. But, anyway. So, I was on Inside Out West and everybody else seen it except for me. And she wanted to stop me from doing it. She said that: &ldquo;I don&rsquo;t want you to do it,&rdquo; she said, because... Because they&rsquo;re living in the past and they think I&rsquo;m going to get bullied and beaten up because I&rsquo;m HIV-positive because&hellip; That&rsquo;s your view in it? And maybe some of them... Modern view, I don&rsquo;t know. I ain&rsquo;t had that, I ain&rsquo;t seen that much discrimination around me. Fortunately. Apart from within the gay community and, but, that goes&hellip;
</p>
<p>
So, and that&hellip; Then that&hellip; But my sisters and my brother-in-laws they don&rsquo;t care, and my niece and my nephew. She didn&rsquo;t even really want me to tell my niece and nephew and they were&hellip; Five years ago, so twenty-five&hellip; So she was twenty and Mum was trying to keep it a secret from them that I was HIV-positive. And then yeah my sister told her because it was easier. And my nephew said: &ldquo;As long as I won&rsquo;t get it.&rdquo; She said: &ldquo;Well, no, no you won&rsquo;t get it, because it&rsquo;s blood-borne, innit?&rdquo; You won&rsquo;t. It&rsquo;s difficult to get, innit? Really.
</p>
<p>
Yeah. But that was the only thing close, my Mum, but the rest of the family been alright it&rsquo;s just&hellip; And I don&rsquo;t know why she was so, so very frightened of it. I don&rsquo;t&hellip; It&rsquo;s beyond my comprehension, on that one. Maybe it&rsquo;s lack of understanding.
</p>
<p>
Yeah. And maybe she thought&hellip; Then she said, then she said about sexuality, she said: &ldquo;We&rsquo;re never gonna bother with men again.&rdquo; And I thought&hellip; Never gonna bother with men again, and I thought: &lsquo;Well, you don&rsquo;t actually get this, do you?&rsquo; It&rsquo;s like&hellip; it&rsquo;s what&rsquo;s in your brain, innit? Even to the point she said, to somebody, she said: &ldquo;Well, Ashley didn&rsquo;t want children.&rdquo; Why was I gonna have children? Yeah, she said&hellip; Well, she was trying to make out the reason why I didn&rsquo;t have children. Without telling anybody I was gay.
</p>
<p>
Yes. So it&rsquo;s still covering tracks, you see, that why, that&rsquo;s why it&rsquo;s still difficult. So she just got the television programme, she just about managed that. And then I done the same as you in the Evening Post, that article and it was quite revealing &lsquo;cause even Lauren said it was a bit brutal. And I thought: &lsquo;Well, you know&hellip; there&rsquo;s no point in doing it unless it&rsquo;s brutal, is it?&rsquo;
</p>
<p>
I consider myself a young 60 year old, non-binary trans, HIV-positive&hellip; HIV-positive, doing my very best for the HIV-community and living my life to the absolute full. Full life possible. Still with many interests and&hellip; Yup. That&rsquo;s how I would describe myself.
</p>
<p>
I just, I just&hellip; I just think that it&rsquo;s time to for this stigma to end. It&rsquo;s time to stop judging people, that was the headline. But I&hellip; Just stop judging people who are HIV-positive because you don&rsquo;t know what&rsquo;s going to happen to you, you know. It could happen to anybody and, erm&hellip; The amount of married men I&rsquo;ve been with, you know. It&rsquo;s&hellip; so we&rsquo;re all&hellip; Everybody&rsquo;s guilty of something. So don&rsquo;t just judge the HIV-positive ones because they were the ones who were unfortunate enough to get it. Could still be, could still be the negative ones who get it now, &lsquo;cause it&rsquo;s still going on. It&rsquo;s never gonna end, no matter what they say, it&rsquo;s never gonna end because people are people. Don&rsquo;t think so. &lsquo;Cause human nature.
</p>
<p>
It&rsquo;s alright thinking that&hellip; thinking that it&rsquo;s going to end because that&rsquo;s, that&rsquo;s brilliant, that&rsquo;s like a good thing to aim at but I think the chance of zero transmission, especially when my GP, I had, they invited me in for a pneumonia jab so I went down and she said: &ldquo;Why are you due a pneumonia jab?&rdquo; And I said: &ldquo;Well, I&rsquo;m HIV-positive.&rdquo; So she said: &ldquo;I&rsquo;ll just have to check and see if you are.&rdquo; Yeah. So, this is&hellip; this is the level and a doctor who I seen to get a letter to work part-time said: &ldquo;You&rsquo;ll have to tell me about HIV because I don&rsquo;t know anything about it.&rdquo; So, this is why it&rsquo;s never gonna end. Now this is a Bristol, this is Bristol, this is Beechwood Practice and they don&rsquo;t know anything about it.
</p>
<p>
Yeah. But so&hellip; So that&rsquo;s what you&rsquo;re up against and besides that they&rsquo;re not teaching it in schools enough, are they? They sort of go past that a bit. So we&rsquo;ll have sex education but we&rsquo;ll go past the diseases. And also down the doctor&rsquo;s, I took, I took my Mum down for an appointment and on the wall they had chlamydia tests. So I looked at them and she came up and said: &ldquo;That&rsquo;s for up to 18 year-olds,&rdquo; and I said: &ldquo;Yeah&hellip;&rdquo; - no, 21 year-olds &ndash; I said: &ldquo;Where&rsquo;s the HIV tests?&rdquo; &ldquo;Oh, we don&rsquo;t do that.&rdquo; So, so this is why it&rsquo;s never going&hellip; This is why I don&rsquo;t think it&rsquo;s ever going to go away. You might get small transmissions, you might get down to single figures, but I don&rsquo;t think the zero&hellip; I don&rsquo;t think the zero is going to be here.
</p>
`;
module.exports = ashley;
