// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-details-js": () => import("./../../../src/pages/our-details.js" /* webpackChunkName: "component---src-pages-our-details-js" */),
  "component---src-pages-our-intention-js": () => import("./../../../src/pages/our-intention.js" /* webpackChunkName: "component---src-pages-our-intention-js" */),
  "component---src-pages-our-participants-js": () => import("./../../../src/pages/our-participants.js" /* webpackChunkName: "component---src-pages-our-participants-js" */),
  "component---src-pages-our-resources-js": () => import("./../../../src/pages/our-resources.js" /* webpackChunkName: "component---src-pages-our-resources-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-story-page-index-js": () => import("./../../../src/templates/StoryPage/index.js" /* webpackChunkName: "component---src-templates-story-page-index-js" */)
}

